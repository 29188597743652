// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require('jquery')
require("jquery-ui")
require("@rails/activestorage").start()
require("channels")

import 'bootstrap' 
import 'css/styles';
import 'css/menu_vertical';
import 'css/multi-select.css';
import 'js/jquery.validate';
import 'js/my_scripts.js';
import 'js/sortable.js';
import 'js/jquery.multi-select';
import 'js/quicksearch.js';

import $ from 'jquery';
global.$ = jQuery;
import 'select2';
import 'select2/dist/css/select2.css';
import "cocoon";

//librería para fijar las filas y columnas de las tablas
import 'js/freeze-table';


//para que al cambiar de ancho, se ajuste la librería de freeze-table
$(document).on('turbolinks:load', function(){

  const resize_ob = new ResizeObserver(function(entries) {
    // since we are observing only a single element, so we access the first element in entries array
    let rect = entries[0].contentRect;

    // current width & height
    let width = rect.width;
    let height = rect.height;

    //console.log('Current Width : ' + width);
    //console.log('Current Height : ' + height);

    $(".table-head-only").freezeTable({
      'freezeColumn': false,
    });

    $(".table-with-scrollbar").freezeTable({
      'scrollBar': true,
    });
  });

  // start observing for resize
  resize_ob.observe(document.querySelector(".page-content"));

});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)




function calendar() {
  // jquery-ui
  $('.calendarField').datepicker( { 
      closeText: 'Cerrar',
      prevText: '<Ant',
      nextText: 'Sig>',
      currentText: 'Hoy',
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      weekHeader: 'Sm',
      dateFormat: 'dd-mm-yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
  } );
}

window.lenguague_script = function(selector, compFunc) {
  //cambia los textos de jquery validator a español
  $.extend($.validator.messages, {
    required: "Este campo es necesario.",
    remote: "Dato existente.",
    email: "Por favor ingrese un correo valido.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date ( ISO ).",
    number: "Por favor ingrese un numero valido.",
    digits: "Please enter only digits.",
    equalTo: "Por favor ingresa el mismo valor.",
    notEqualTo: "El cambio en Planeación de Ruta requiere un Agente diferente.",
    maxlength: $.validator.format( "Please enter no more than {0} characters." ),
    minlength: $.validator.format( "Por favor ingrese al menos {0} caracteres." ),
    rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
    range: $.validator.format( "Please enter a value between {0} and {1}." ),
    max: $.validator.format( "Ingrese un valor igual o menor que {0}." ),
    min: $.validator.format( "Ingrese un valor mayor a {0}." ),
    step: $.validator.format( "Please enter a multiple of {0}." )
  });
}

$(document).on('turbolinks:load', function(){

  /*menu vertical*/
  jQuery(function ($) {

      $(".sidebar-dropdown > a").click(function(event) {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    event.stopPropagation();    
    });

    $("#close-sidebar").click(function(event) {
      $(".page-wrapper").removeClass("toggled");
      event.stopPropagation();    
    });
    $("#show-sidebar").click(function(event) {
      $(".page-wrapper").addClass("toggled");
      event.stopPropagation();    
    });

    //para que en pantallas menores se oculte el menu
    if ($(window).width() < 1178) {
      $(".page-wrapper").removeClass("toggled");
    }
  });



  $('.paginacion_remota .pagination a').attr('data-remote', 'true');

  $('.js-states').select2();

	$('.nuevo .js-states2').select2().on('change', function () {
		$('.cuerpo_form').show();
	});
	calendar();

});

window.addEventListener('DOMContentLoaded', () => {
  $('.js-states').select2();

    $('.nuevo .js-states2').select2().on('change', function () {
		$('.cuerpo_form').show();
	});

});


