$(document).on('turbolinks:load', function() {
  $(".limpiar_form").click(function(){
	  $(".frm").val('');
	  $(".campo").val('');
  });

  $(".limpiar_form1").click(function(){
	  $(".frm1").val('');
	  $(".campo").val('');
  }); 




    $("#contenedor_elementos_seleccionados").prop( "disabled", true );
    var obtener_val_añadidos = function(event, ui){
      $("#contenedor_elementos_seleccionados").prop( "disabled", false );
      $("#contenedor_elementos_seleccionados").val("");
      let añadidos = document
      .getElementById("sortable-6")
      .querySelectorAll('li');
      var arreglo=[]



      añadidos.forEach(item => {
        arreglo.push(item.getAttribute('value'));
        $("#contenedor_elementos_seleccionados").val(arreglo);
      });
    };

    $(function() {  
       $( "#sortable-5, #sortable-6" ).sortable({  
          connectWith: "#sortable-5, #sortable-6",  
          stop: obtener_val_añadidos
       });  
    });  

   
$('.multi_select').multiSelect({
    selectableHeader: "<input type='text' class='search-input form-control' autocomplete='off' placeholder='Registros disponibles'>",
    selectionHeader: "<input type='text' class='search-input form-control' autocomplete='off' placeholder='Registros añadidos'>",
    afterInit: function(ms){
      var that = this,
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';

      that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
      .on('keydown', function(e){
        if (e.which === 40){
          that.$selectableUl.focus();
          return false;
        }
      });

      that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
      .on('keydown', function(e){
        if (e.which == 40){
          that.$selectionUl.focus();
          return false;
        }
      });
    },
    afterSelect: function(){
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function(){
      this.qs1.cache();
      this.qs2.cache();
    }
  }); 




});
