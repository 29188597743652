$(document).on('turbolinks:load', function() {

  //para que rails 6 tome en cuenta una funcion declarada en otro archivo se debe seguir el siguiente ejemplo
  //window.myFunction_name = function() { ... }

  window.sortTable = function(selector, compFunc) {
    var mySelector = '.sortable';
    var myCompFunc = function($td1, $td2, isAsc) {
      var v1 = $.trim($td1.text()).replace(/,|\s+|%/g, '');
      var v2 = $.trim($td2.text()).replace(/,|\s+|%/g, '');
      var pattern = /^\d+(\.\d*)?$/;
      if (pattern.test(v1) && pattern.test(v2)) {
        v1 = parseFloat(v1);
        v2 = parseFloat(v2);
      }

      return isAsc ? v1 > v2 : v1 < v2;
    };

    var doSort = function($tbody, index, compFunc, isAsc)
    {
      var $trList = $tbody.find("tr");
      var len = $trList.length;
      for(var i=0; i<len-1; i++) {
        for(var j=0; j<len-i-1; j++) {
          var $td1 = $trList.eq(j).find("td").eq(index);
          var $td2 = $trList.eq(j+1).find("td").eq(index);

          if (compFunc($td1, $td2, isAsc)) {
            var t = $trList.eq(j+1);
            $trList.eq(j).insertAfter(t);
            $trList = $tbody.find("tr");
          }
        }
      }
    }

    var init = function() {
      var $th = $("th" + selector);
      this.$table = $th.closest("table");
      var that = this;
      $th.click(function(){
        var index = $(this).index();
        var asc = $(this).attr('data-asc');
        isAsc = asc === undefined ? true : (asc > 0 ? true : false);

        //oculta las flechas al hacer click en una columna para cambio de col
        $( ".arrow_down_library" ).hide();
        $( ".arrow_up_library" ).hide();

        //verifica si es ascendente o no para colocar la flecha correspondiente
        if (isAsc == true){
          $( this ).find( 'i.arrow_up_library' ).css("display", "");
        }else{
          $( this ).find( 'i.arrow_down_library' ).css("display", "");

        }

        doSort(that.$table.find("tbody"), index, compFunc, isAsc);

        $(this).attr('data-asc', 1 - (isAsc ? 1 : 0));
      });

      $th.css({'cursor': 'pointer'})
         .attr('title', 'Haga clic para ordenar')
         .append('&nbsp;<i class="material-icons mr-2 arrow_down_library" style="display: none; font-size: 1.4em !important;">arrow_drop_down</i><i class="material-icons mr-2 arrow_up_library" style="display: none; font-size: 1.4em !important;">arrow_drop_up</i>');
    };


    selector = selector || mySelector;
    compFunc = compFunc || myCompFunc;

    init();
  }




});
